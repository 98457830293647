<template>
  <div class="num_box">
    <div class="tras_box" :style="{ top: -1 * value * 20 + 'px' }">
      <p
        class="num display_flex align-items_center"
        v-for="item in list"
        :key="item"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "NumRun",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    return {
      list,
    };
  },
});
</script>
<style lang="scss" scoped>
.num_box {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 20px;
  overflow: hidden;
  margin-bottom: 2px;

  .tras_box {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
    transform-origin: 0 0;
    transition: top 0.8s;
  }

  .num {
    width: 12px;
    height: 20px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    font-family: DIN-Bold;
  }
}
</style>
