import { Request } from "@/utils/axios";
import { AxiosPromise } from "axios";

interface getModelListValue {
  rows: number;
  page: number;
  category?: string | number;
  name?: string;
}

// 获取分类
export const getCateList = (): AxiosPromise => {
  return Request.axiosInstance({
    url: "/case/getCateList",
    method: "GET",
  });
};

// 获取模型列表
export const getModelList = (obj: getModelListValue): AxiosPromise => {
  return Request.axiosInstance({
    url: "/case/getCaseList",
    method: "POST",
    data: {
      pageInfo: {
        rows: obj.rows,
        page: obj.page,
      },
      reqData: {
        name: obj.name,
        category: obj.category,
      },
    },
  });
};

// 访问量
export const modelbaseFlux = (): AxiosPromise => {
  return Request.axiosInstance({
    url: "//api.513dvr.com/model/getModelList",
    method: "POST",
    data: {
      vr: true,
      sid: "00000000001",
    },
  });
};

// 添加访问量
export const modelbaseFluxAdd = (
  fluxReal: number,
  todayReal: number
): AxiosPromise => {
  return Request.axiosInstance({
    url: "//api.513dvr.com/model/upDateModel",
    method: "POST",
    data: {
      flux_real: fluxReal,
      today_real: todayReal,
      sid: "00000000001",
    },
  });
};
