
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import NumRun from "@/components/NumRun.vue";
import utils from "@/utils/utils";
import { modelbaseFlux, modelbaseFluxAdd } from "@/api/service";
import { Swipe, SwipeItem, Notify, Overlay } from "vant";
interface stateFace {
  getNumber: number[];
  current: number;
}
export default defineComponent({
  components: {
    Navbar,
    NumRun,
    [Overlay.name]: Overlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup(prop, ctx) {
    // 静态数据
    const search = false;
    const timeYear = new Date().getFullYear();
    const swipeList = [
      {
        name: "钰乘礼品",
        cate: "展厅",
        pic: require("../assets/case1.gif"),
        url: "//my.513dvr.com/view/DEMO/LjMqvQ2xHfj.html",
      },
      {
        name: "遂溪河头革命红色印记",
        cate: "党建",
        pic: require("../assets/case2.jpg"),
        url: "//www.shine-tech.net/shtml/shine2018/sxgmhsyj/",
      },
      {
        name: "广东方志馆",
        cate: "文博",
        pic: require("../assets/case3.jpg"),
        url: "//my.513dvr.com/view2/vr/fangzhiguan.html#1L",
      },
      {
        name: "互联网+大学生双创大赛",
        cate: "校园",
        pic: require("../assets/case4.jpg"),
        url: "//my.513dvr.com/view2/vr/contest.html",
      },
      {
        name: "智能立式加工中心",
        cate: "物体",
        pic: require("../assets/case5.jpg"),
        url: "//model.513dvr.com/ws3d/sj001/1009/web/",
      },
      {
        name: "云起连锁海景客栈",
        cate: "民宿",
        pic: require("../assets/case6.jpg"),
        url: "//ms.513dvr.com/view-40.html",
      },
    ];
    const overlayShow = ref(false);

    onMounted(() => {
      fluxData();
    });

    var state: stateFace = reactive({
      // 访问量
      getNumber: [0],
      // 轮播图下标
      current: 1,
    });

    // 转成数字数组
    const converToArray = (val: number): number[] =>
      [...`${val}`].map((el) => parseInt(el));

    // 获取访问量
    const fluxData = async () => {
      const res = await modelbaseFlux();
      if (res.data.models && res.data.models.length > 0) {
        // 将访问量转成数组
        let num = res.data.models[0];
        state.getNumber = converToArray(num.flux_real + num.flux_sham);
        // 1秒后加一个当前访问数
        setTimeout(() => {
          state.getNumber = converToArray(num.flux_real + num.flux_sham + 1);
        }, 1000);

        // 取出本地存放的时间，如果没有则添加访问量，有则判断是否是今天之前
        let localTime: any = localStorage.getItem("fluxInfo");
        let time = JSON.parse(localTime);
        if (time) {
          let localDay: string = utils.format(time, "yyyyMMdd");
          let nowDay: string = utils.format(new Date() + "", "yyyyMMdd");
          // 获取存储的年月日和当前时间的年月日，相减大于0，则超过当天，可以再添加
          if (parseInt(nowDay) - parseInt(localDay) > 0) {
            fluxAdd(num.flux_real + 1, num.today_real + 1);
          }
        } else {
          fluxAdd(num.flux_real + 1, num.today_real + 1);
        }
      } else {
        Notify({ type: "danger", message: "数据异常！" });
      }
    };

    // 添加访问量
    const fluxAdd = async (fluxReal: number, todayReal: number) => {
      const res = await modelbaseFluxAdd(fluxReal, todayReal);
      if (res.data.code == 200) {
        // 存当前时间到本地，防止今日重复添加访问量
        let time = new Date() + "";
        localStorage.setItem("fluxInfo", JSON.stringify(Date.parse(time)));
        console.log("-----添加访问量成功-----");
      }
    };
    // 修改指示器
    const onSwiperChange = (index: number) => {
      state.current = index + 1;
    };

    // 前往案例
    const toCase = (val: string) => {
      window.open(val);
    };

    // 打开功能演示视频，bgm开关
    const BgmSwitch = (val: boolean) => {
      overlayShow.value = val;
      setTimeout(() => {
        let dom: any = document.getElementById("overlayVideo");
        let musicDom: any = document.getElementById("bgmusic");
        if (val) {
          dom.play();
          musicDom.pause();
        } else {
          dom.pause();
        }
      }, 500);
      ctx.emit("BgmSwitch", !val);
    };
    return {
      search,
      timeYear,
      swipeList,
      overlayShow,
      ...toRefs(state),
      onSwiperChange,
      toCase,
      BgmSwitch,
    };
  },
});
