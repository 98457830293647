<template>
  <div class="home">
    <Navbar :search="search" />
    <div class="home_content">
      <div class="home_content_visitor display_flex align-items_flex-end">
        <span style="margin-right: 20px">您好，您是第</span>
        <template v-for="(num, index) in getNumber" :key="index">
          <NumRun :value="parseInt(num)" />
        </template>
        <span style="margin-left: 20px">位游客</span>
      </div>
      <div class="home_content_banner">
        <img src="../assets/banner.png" />
      </div>
      <div class="home_content_search">
        <router-link
          class="home_content_search_item display_flex align-items_center justify-content_flex-justify"
          to="/search"
        >
          <span>搜索案例名称</span>
          <img src="../assets/search-grey.png" />
        </router-link>
      </div>
      <div class="home_content_swiper">
        <div
          class="home_content_swiper_title display_flex align-items_cneter justify-content_flex-justify"
        >
          <p
            class="home_content_swiper_title_label display_flex align-items_center"
          >
            行业案例精选
            <img src="../assets/label.gif" />
          </p>

          <p class="home_content_swiper_title_indicator">
            <span>{{ current }}</span
            >/{{ swipeList.length }}
          </p>
        </div>
        <van-swipe
          :autoplay="4000"
          :width="280"
          :show-indicators="false"
          @change="onSwiperChange"
        >
          <van-swipe-item v-for="(item, index) in swipeList" :key="index">
            <div class="swipe-item-box" @click="toCase(item.url)">
              <img :src="item.pic" />
              <p
                class="display_flex align-items_cneter justify-content_flex-justify"
              >
                <span>{{ item.name }}</span
                ><span>|&nbsp;&nbsp;{{ item.cate }}</span>
              </p>
              <p
                class="swipe-item-btn display_flex align-items_cneter justify-content_flex-center"
              >
                查看案例<van-icon name="arrow" />
              </p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="home_content_footer_banner">
        <img src="../assets/product-video.png" @click="BgmSwitch(true)" />
        <p>Copyright © {{ timeYear }} 广东顾诚智能科技有限公司 版权所有</p>
      </div>
    </div>
    <van-overlay :show="overlayShow" @click="BgmSwitch(false)">
      <div class="overlay_wrapper" @click.stop>
        <video
          id="overlayVideo"
          class="overlay_wrapper_video"
          controls
          src="../assets/funcShow.mp4"
        ></video>
        <van-icon
          class="overlay_wrapper_icon"
          name="clear"
          @click="BgmSwitch(false)"
          color="#1989fa"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import NumRun from "@/components/NumRun.vue";
import utils from "@/utils/utils";
import { modelbaseFlux, modelbaseFluxAdd } from "@/api/service";
import { Swipe, SwipeItem, Notify, Overlay } from "vant";
interface stateFace {
  getNumber: number[];
  current: number;
}
export default defineComponent({
  components: {
    Navbar,
    NumRun,
    [Overlay.name]: Overlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup(prop, ctx) {
    // 静态数据
    const search = false;
    const timeYear = new Date().getFullYear();
    const swipeList = [
      {
        name: "钰乘礼品",
        cate: "展厅",
        pic: require("../assets/case1.gif"),
        url: "//my.513dvr.com/view/DEMO/LjMqvQ2xHfj.html",
      },
      {
        name: "遂溪河头革命红色印记",
        cate: "党建",
        pic: require("../assets/case2.jpg"),
        url: "//www.shine-tech.net/shtml/shine2018/sxgmhsyj/",
      },
      {
        name: "广东方志馆",
        cate: "文博",
        pic: require("../assets/case3.jpg"),
        url: "//my.513dvr.com/view2/vr/fangzhiguan.html#1L",
      },
      {
        name: "互联网+大学生双创大赛",
        cate: "校园",
        pic: require("../assets/case4.jpg"),
        url: "//my.513dvr.com/view2/vr/contest.html",
      },
      {
        name: "智能立式加工中心",
        cate: "物体",
        pic: require("../assets/case5.jpg"),
        url: "//model.513dvr.com/ws3d/sj001/1009/web/",
      },
      {
        name: "云起连锁海景客栈",
        cate: "民宿",
        pic: require("../assets/case6.jpg"),
        url: "//ms.513dvr.com/view-40.html",
      },
    ];
    const overlayShow = ref(false);

    onMounted(() => {
      fluxData();
    });

    var state: stateFace = reactive({
      // 访问量
      getNumber: [0],
      // 轮播图下标
      current: 1,
    });

    // 转成数字数组
    const converToArray = (val: number): number[] =>
      [...`${val}`].map((el) => parseInt(el));

    // 获取访问量
    const fluxData = async () => {
      const res = await modelbaseFlux();
      if (res.data.models && res.data.models.length > 0) {
        // 将访问量转成数组
        let num = res.data.models[0];
        state.getNumber = converToArray(num.flux_real + num.flux_sham);
        // 1秒后加一个当前访问数
        setTimeout(() => {
          state.getNumber = converToArray(num.flux_real + num.flux_sham + 1);
        }, 1000);

        // 取出本地存放的时间，如果没有则添加访问量，有则判断是否是今天之前
        let localTime: any = localStorage.getItem("fluxInfo");
        let time = JSON.parse(localTime);
        if (time) {
          let localDay: string = utils.format(time, "yyyyMMdd");
          let nowDay: string = utils.format(new Date() + "", "yyyyMMdd");
          // 获取存储的年月日和当前时间的年月日，相减大于0，则超过当天，可以再添加
          if (parseInt(nowDay) - parseInt(localDay) > 0) {
            fluxAdd(num.flux_real + 1, num.today_real + 1);
          }
        } else {
          fluxAdd(num.flux_real + 1, num.today_real + 1);
        }
      } else {
        Notify({ type: "danger", message: "数据异常！" });
      }
    };

    // 添加访问量
    const fluxAdd = async (fluxReal: number, todayReal: number) => {
      const res = await modelbaseFluxAdd(fluxReal, todayReal);
      if (res.data.code == 200) {
        // 存当前时间到本地，防止今日重复添加访问量
        let time = new Date() + "";
        localStorage.setItem("fluxInfo", JSON.stringify(Date.parse(time)));
        console.log("-----添加访问量成功-----");
      }
    };
    // 修改指示器
    const onSwiperChange = (index: number) => {
      state.current = index + 1;
    };

    // 前往案例
    const toCase = (val: string) => {
      window.open(val);
    };

    // 打开功能演示视频，bgm开关
    const BgmSwitch = (val: boolean) => {
      overlayShow.value = val;
      setTimeout(() => {
        let dom: any = document.getElementById("overlayVideo");
        let musicDom: any = document.getElementById("bgmusic");
        if (val) {
          dom.play();
          musicDom.pause();
        } else {
          dom.pause();
        }
      }, 500);
      ctx.emit("BgmSwitch", !val);
    };
    return {
      search,
      timeYear,
      swipeList,
      overlayShow,
      ...toRefs(state),
      onSwiperChange,
      toCase,
      BgmSwitch,
    };
  },
});
</script>
<style lang="scss" scoped>
.home_content {
  background: #f1f1f1;
  padding-bottom: 50px;

  &_visitor {
    padding: 18px 20px 20px 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
  }

  &_banner {
    width: 100%;
    padding: 0 20px 15px 20px;
    background-color: #fff;

    > img {
      width: 100%;
      height: 100px;
      border-radius: 4px;
    }
  }

  &_search {
    background-color: #fff;
    padding: 0 20px;

    &_item {
      width: 100%;
      height: 44px;
      background: #f6f7fa;
      border-radius: 2px 22px 22px 22px;
      color: #cccccc;
      font-size: 14px;
      font-weight: 400;
      padding: 0 20px;

      > img {
        width: 15px;
        height: 15px;
      }
    }
  }

  &_swiper {
    &_title {
      padding: 30px 20px 17px 30px;
      background-color: #fff;

      &_label {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
        width: calc(100% - 50px);

        > img {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
      }

      &_indicator {
        font-size: 13px;
        font-family: DIN-Bold;
        font-weight: 500;
        color: #999999;
        letter-spacing: 5px;
        width: 50px;
        text-align: right;

        > span {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
      }
    }

    .van-swipe {
      height: 290px;
      padding-top: 10px;
      overflow-y: hidden;
    }

    .van-swipe-item {
      width: 280px !important;
      padding: 0 10px;
      background: #f1f1f1;

      .swipe-item-box {
        position: relative;
        width: 260px;
        height: 254px;
        padding: 5px;
        box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        > img {
          width: 250px;
          height: 170px;
        }

        > p {
          padding: 10px;

          > span:first-of-type {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }

          > span {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
        }

        .swipe-item-btn {
          position: absolute;
          bottom: -20px;
          left: calc((100% - 100px) / 2);
          width: 100px;
          height: 40px;
          background: #bbbbbb;
          border-radius: 2px;
          color: #fff;
          font-size: 14px;

          .van-icon {
            margin-top: 3px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  // &_swiper {
  //   &_title {
  //     padding: 30px 20px 17px 30px;
  //     background-color: #fff;
  //     position: relative;

  //     &_label {
  //       font-size: 15px;
  //       font-weight: bold;
  //       color: #333333;
  //       width: 100%;

  //       > img {
  //         height: 16px;
  //         margin-left: 8px;
  //       }
  //     }

  //     &_indicator {
  //       font-size: 13px;
  //       font-family: DIN-Bold;
  //       font-weight: 500;
  //       color: #999999;
  //       letter-spacing: 5px;
  //       width: 50px;
  //       text-align: right;
  //       right: 20px;
  //       left: auto;
  //       width: 50px;
  //       bottom: 20px;
  //     }
  //   }
  // }

  // .swiper-wrapper {
  //   height: 284px;
  // }

  // .swiper-slide {
  //   width: 260px;
  //   height: 254px;
  // }

  // .swipe-item-box {
  //   position: relative;
  //   padding: 5px;
  //   margin: 10px;
  //   box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.06);
  //   border-radius: 4px;
  //   background-color: #fff;
  //   > img {
  //     width: 250px;
  //     height: 170px;
  //   }

  //   > p {
  //     padding: 10px;

  //     > span:first-of-type {
  //       font-size: 15px;
  //       font-weight: bold;
  //       color: #333333;
  //     }

  //     > span {
  //       font-size: 13px;
  //       font-weight: 400;
  //       color: #999999;
  //     }
  //   }

  //   .swipe-item-btn {
  //     position: absolute;
  //     bottom: -20px;
  //     left: calc((100% - 100px) / 2);
  //     width: 100px;
  //     height: 40px;
  //     background: #bbbbbb;
  //     border-radius: 2px;
  //     color: #fff;
  //     font-size: 14px;

  //     .van-icon {
  //       margin-top: 3px;
  //       margin-left: 8px;
  //     }
  //   }
  // }

  &_footer_banner {
    width: 100%;
    padding: 30px 20px 15px 20px;
    background-image: linear-gradient(#f1f1f1, #ffffff);

    > img {
      width: 100%;
      height: 100px;
      border-radius: 4px;
      margin-bottom: 30px;
    }

    > p {
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      color: #cccccc;
    }
  }
}

.overlay_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;

  &_video {
    width: 100vh;
    height: 100vw;
    transform: rotate(90deg);
  }

  &_icon {
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
}
</style>
